// Import everything from autoload folder
import './autoload/ada-compliance.js'; import './autoload/foundation.js'; // eslint-disable-line

// Import local dependencies
// import './plugins/lazyload';
import './plugins/modernizr.min';
import 'slick-carousel';
import 'jquery-match-height';
import objectFitImages from 'object-fit-images';
import '@fancyapps/fancybox/dist/jquery.fancybox.min';
import { jarallax, jarallaxElement } from 'jarallax';
import ScrollOut from 'scroll-out';
/**
 * Import scripts from Custom Divi blocks
 */
// eslint-disable-next-line import/no-unresolved
//import '../blocks/divi/example-block/index.js';

/**
 * Import scripts from Custom Elementor widgets
 */
// eslint-disable-next-line import/no-unresolved
//import '../blocks/elementor/example-widget/index.js';

/**
 * Import scripts from Custom ACF Gutenberg blocks
 */
// eslint-disable-next-line import/no-unresolved
//import '../blocks/gutenberg/acf-example-block/index.js';
/**
 * gsap
 */
import { gsap } from 'gsap';
/**
 * Init foundation
 */
$(document).foundation();

/**
 * Fit slide video background to video holder
 */
function resizeVideo() {
  let $holder = $('.videoHolder');
  $holder.each(function () {
    let $that = $(this);
    let ratio = $that.data('ratio') ? $that.data('ratio') : '16:9';
    let width = parseFloat(ratio.split(':')[0]);
    let height = parseFloat(ratio.split(':')[1]);
    $that.find('.video').each(function () {
      if ($that.width() / width > $that.height() / height) {
        $(this).css({
          width: '100%',
          height: 'auto',
        });
      } else {
        $(this).css({
          width: ($that.height() * width) / height,
          height: '100%',
        });
      }
    });
  });
}

//Testimonials slider
$('.testimonials__slider').slick({
  infinite: true,
  slidesToShow: 3,
  slidesToScroll: 1,
  arrows: true,
  dots: false,
  rows: false,
  responsive: [
    {
      breakpoint: 1024,
      settings: {
        slidesToShow: 2,
        slidesToScroll: 1,
      },
    },
    {
      breakpoint: 768,
      settings: {
        slidesToShow: 1,
        slidesToScroll: 1,
      },
    },
  ],
});

function initializeSlick() {
  let slider = $('.home-practice-slider');
  if ($(window).width() <= 390) {
    if (!$(slider).hasClass('slick-initialized')) {
      $(slider).slick({
        infinite: true,
        slidesToShow: 1,
        slidesToScroll: 1,
        arrows: true,
        dots: false,
      });
    }
  } else {
    if ($(slider).hasClass('slick-initialized')) {
      $(slider).slick('unslick');
    }
  }
}

/**
 * Scripts which runs after DOM load
 */
$(document).on('ready', function () {
  let arrow = document.querySelector('.home-hero__arrow-down');
  let section = document.querySelector('.our-firm');

  if (arrow && section) {
    arrow.addEventListener('click', function () {
      window.scrollTo({
        top: section.offsetTop,
        behavior: 'smooth',
      });
    });
  }
  /**
   * GSAP Sway Animation
   */
  gsap.to('#line1', {
    duration: 5,
    repeat: -1,
    yoyo: true,
    attr: {
      d: 'M0 101H50C100 101 200 95 300 88C400 81 500 75 600 71C700 68 800 75 900 70C1000 65 1100 55 1200 50C1300 45 1400 50 1440 55',
    },
    ease: 'power1.inOut',
  });

  gsap.to('#line2', {
    duration: 6,
    repeat: -1,
    yoyo: true,
    attr: {
      d: 'M0 135H60C120 135 240 120 360 110C480 100 600 85 720 80C840 75 960 80 1080 70C1200 60 1320 40 1440 20',
    },
    ease: 'power1.inOut',
  });

  gsap.to('#line3', {
    duration: 6,
    repeat: -1,
    yoyo: true,
    ease: 'power1.inOut',
    attr: {
      d: 'M1440 220H1380C1300 215 1200 195 1100 185C960 180 840 200 720 190C600 180 480 140 360 120C240 100 120 90 40 95L0 100',
    },
  });

  gsap.to('#line4', {
    duration: 8,
    repeat: -1,
    yoyo: true,
    ease: 'power1.inOut',
    attr: {
      d: 'M1440 280H1340C1260 275 1150 250 1040 240C920 230 800 250 680 240C560 230 440 180 320 160C200 140 80 130 40 135L0 140',
    },
  });

  gsap.to('#line5', {
    duration: 3,
    repeat: -1,
    yoyo: true,
    ease: 'power1.inOut',
    attr: {
      d: 'M-430 310H-360C-290 310 -150 310 -10 285C130 260 270 220 410 205C550 190 690 215 830 200C970 185 1110 125 1250 110C1390 95 1530 115 1600 125L1652.3 140',
    },
  });

  gsap.to('#line6', {
    duration: 4,
    repeat: -1,
    yoyo: true,
    ease: 'power1.inOut',
    attr: {
      d: 'M-430 390H-350C-270 390 -110 390 50 345C210 300 370 230 530 210C690 190 850 230 1010 195C1170 160 1330 50 1490 20C1550 5 1610 0 1652.3 10',
    },
  });

  gsap.to('#line7', {
    duration: 6,
    repeat: -1,
    yoyo: true,
    ease: 'power1.inOut',
    attr: {
      d: 'M-521 312.893H-451.59C-382.18 312.893 -243.36 340 -104.54 300C34.2806 260 173.101 240 311.921 220C450.741 200 589.561 260 728.381 230C867.202 200 1006.02 160 1144.84 150C1283.66 140 1422.48 170 1491.89 180L1561.3 190',
    },
  });

  gsap.to('#line8', {
    duration: 5,
    repeat: -1,
    yoyo: true,
    ease: 'power1.inOut',
    attr: {
      d: 'M-521 419H-441.853C-362.706 419 -204.413 450 -46.1194 400C112.174 350 270.468 250 428.761 230C587.055 210 745.348 250 903.642 220C1061.94 190 1220.23 50 1378.52 20C1441.08 10 1503.63 0 1561.3 40',
    },
  });

  /**
   * Blog loadmore
   */
  let postPerPage = 9;

  function loadScrollPosts(response, taxonomy = false) {
    let offset = taxonomy ? 0 : $('.post__wrapper').length;
    let postType = $('.post-load').data('post-type');
    let category = $('.post-load').data('cat');

    $.ajax({
      type: 'GET',
      dataType: 'json',
      url: ajax_object.ajax_url,
      data: {
        postType: postType,
        action: 'loadmore',
        offset: offset,
        postperpage: postPerPage,
        category: category,
      },
      beforeSend: function () {
        $('.ajax-loading').hide();
      },
      success: function (response) {
        $('.ajax-loading').hide();
        if (taxonomy) {
          $('.ajax-posts').html(response.html);
          $('.reset').text(name);
          $('.show-cat-btn').removeClass('active');
        } else {
          $('.ajax-posts').append(response.html);
        }
        if ($('.post__wrapper').length < response.post_count) {
          $('.ajax-loading').show();
        }
      },
      error: function (result) {
        console.warn(result);
      },
    });
    return false;
  }
  $('.post-load').click(function () {
    loadScrollPosts();
  });

  $(document).on('click', '.reset', function () {
    $(this).toggleClass('is-active');
    $('.post-list__category_wrapper').slideToggle();
  });

  // Resoueces blog slider
  $('.js-blog-list-slider').slick({
    infinite: true,
    slidesToShow: 3,
    slidesToScroll: 1,
    arrows: true,
    dots: false,
    nextArrow: $('.blog-next'),
    responsive: [
      {
        breakpoint: 1025,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  });

  // Resoueces video slider
  $('.js-video-list-slider').slick({
    infinite: true,
    slidesToShow: 3,
    slidesToScroll: 1,
    arrows: true,
    dots: false,
    nextArrow: $('.video-next'),
    responsive: [
      {
        breakpoint: 1025,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  });

  // faq slider
  $('.js-faq-list-slider').slick({
    infinite: true,
    slidesToShow: 3,
    slidesToScroll: 1,
    arrows: true,
    dots: false,
    nextArrow: $('.faq-next'),
    responsive: [
      {
        breakpoint: 1025,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  });

  // Practice page show more on mobile
  const button = document.querySelector('.practice-list-more');
  const listItems = document.querySelectorAll('.practice-list__item-wrap');
  let isExpanded = false;
  if (button && listItems) {
    button.addEventListener('click', () => {
      isExpanded = !isExpanded;
      listItems.forEach((item, index) => {
        if (index >= 3) {
          item.style.display = isExpanded ? 'block' : 'none';
        }
      });
      button.textContent = isExpanded ? 'Show Less' : 'View More';
    });
  }

  let menuTitle = $('.mobile-practice-menu__title');
  let menuList = $('.mobile-practice-menu__list');

  menuTitle.on('click', function () {
    menuTitle.toggleClass('mobile-practice-menu-active');
    menuList.slideToggle();
  });

  initializeSlick();

  /**
   * Make elements equal height
   */
  $('.matchHeight').matchHeight();

  /**
   * IE Object-fit cover polyfill
   */
  if ($('.of-cover').length) {
    objectFitImages('.of-cover');
  }

  /**
   * Add fancybox to images
   */
  $('.gallery-item')
    .find('a[href$="jpg"], a[href$="png"], a[href$="gif"]')
    .attr('rel', 'gallery')
    .attr('data-fancybox', 'gallery');
  $(
    '.fancybox, a[rel*="album"], a[href$="jpg"], a[href$="png"], a[href$="gif"]'
  ).fancybox({
    minHeight: 0,
    helpers: {
      overlay: {
        locked: false,
      },
    },
  });

  /**
   * Init parallax
   */
  jarallaxElement();
  jarallax(document.querySelectorAll('.jarallax'), {
    speed: 0.5,
  });

  /**
   * Detect element appearance in viewport
   */
  ScrollOut({
    threshold: 0.97,
    once: true,
    onShown: function (element) {
      if ($(element).is('.ease-order')) {
        $(element)
          .find('.ease-order__item')
          .each(function (i) {
            let $this = $(this);
            $(this).attr('data-scroll', '');
            window.setTimeout(function () {
              $this.attr('data-scroll', 'in');
            }, 300 * i);
          });
      }
    },
  });

  const titles = document.querySelectorAll('.to-left');
  // titles.forEach((title) => {
  //   if (title) {
  //     setTimeout(() => {
  //       title.classList.add("visible");
  //     }, 300);
  //   }
  // });

  let delay = 0;

  titles.forEach((title) => {
    if (title) {
      const observer = new IntersectionObserver((entries) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting) {
            setTimeout(() => {
              entry.target.classList.add('visible');
              observer.unobserve(entry.target);
            }, delay);

            delay += 150;
          }
        });
      });

      observer.observe(title);
    }
  });

  /**
   * Remove placeholder on click
   */
  const removeFieldPlaceholder = () => {
    $('input, textarea').each((i, el) => {
      $(el)
        .data('holder', $(el).attr('placeholder'))
        .on('focusin', () => {
          $(el).attr('placeholder', '');
        })
        .on('focusout', () => {
          $(el).attr('placeholder', $(el).data('holder'));
        });
    });
  };

  removeFieldPlaceholder();

  $(document).on('gform_post_render', () => {
    removeFieldPlaceholder();
  });

  /**
   * Scroll to Gravity Form confirmation message after form submit
   */
  $(document).on('gform_confirmation_loaded', function (event, formId) {
    let $target = $('#gform_confirmation_wrapper_' + formId);
    if ($target.length) {
      $('html, body').animate({ scrollTop: $target.offset().top - 50 }, 500);
      return false;
    }
  });

  /**
   * Hide gravity forms required field message on data input
   */
  $('body').on(
    'change keyup',
    '.gfield input, .gfield textarea, .gfield select',
    function () {
      let $field = $(this).closest('.gfield');
      if ($field.hasClass('gfield_error') && $(this).val().length) {
        $field.find('.validation_message').hide();
      } else if ($field.hasClass('gfield_error') && !$(this).val().length) {
        $field.find('.validation_message').show();
      }
    }
  );

  /**
   * Add `is-active` class to menu-icon button on Responsive menu toggle
   * And remove it on breakpoint change
   */
  $(window)
    .on('toggled.zf.responsiveToggle', function () {
      $('.menu-icon').toggleClass('is-active');
    })
    .on('changed.zf.mediaquery', function () {
      $('.menu-icon').removeClass('is-active');
    });

  /**
   * Close responsive menu on orientation change
   */
  $(window).on('orientationchange', function () {
    setTimeout(function () {
      if ($('.menu-icon').hasClass('is-active') && window.innerWidth < 641) {
        $('[data-responsive-toggle="main-menu"]').foundation('toggleMenu');
      }
    }, 200);
  });

  resizeVideo();
});

/**
 * Scripts which runs after all elements load
 */
$(window).on('load', function () {
  // jQuery code goes here

  let $preloader = $('.preloader');
  if ($preloader.length) {
    $preloader.addClass('preloader--hidden');
  }
});

/**
 * Scripts which runs at window resize
 */
$(window).on('resize', function () {
  // jQuery code goes here
  initializeSlick();
  resizeVideo();
});

/**
 * Scripts which runs on scrolling
 */
$(window).on('scroll', function () {
  // jQuery code goes here
});
